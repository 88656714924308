//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-304:_4316,_6980,_8124,_1388,_5396,_9420,_8468,_6068;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-304')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-304', "_4316,_6980,_8124,_1388,_5396,_9420,_8468,_6068");
        }
      }catch (ex) {
        console.error(ex);
      }